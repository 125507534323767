<template>
  <div class="alltitle">
    <div class="butflylikeso wow animate fadeInUp">
      <!-- <img src="../assets/img/Engineering/beautifulthing.png" alt="" /> -->
    </div>
    <div class="fournavoutside wow animate fadeInUp delay-200ms">
      <div class="navtitlehead">
        <div class="navtitleinner" @click="changetitle((newnumber = 1))">
          <img
            v-if="newnumber == 1"
            src="../assets/img/Engineering/tab_icon01red.png"
            alt=""
          />
          <img v-else src="../assets/img/Engineering/tab_icon01.png" alt="" />
          <div :class="newnumber == 1 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.Engineering") }}
          </div>
        </div>
        <!-- <div class="navtitleinner" @click="changeinformation((newnumber = 2))">
          <img
            v-if="newnumber == 2"
            src="../assets/img/Engineering/tab_icon02red.png"
            alt=""
          />
          <img v-else src="../assets/img/Engineering/tab_icon02.png" alt="" />

          <div :class="newnumber == 2 ? 'navtextcolored' : 'navtext'">
            Fotile Engineering
          </div>
        </div> -->
        <div
          class="navtitleinner"
          @click="changetitleportrait((newnumber = 3))"
        >
          <img
            v-if="newnumber == 3"
            src="../assets/img/Engineering/tab_icon03red.png"
            alt=""
          />
          <img v-else src="../assets/img/Engineering/tab_icon03.png" alt="" />
          <div :class="newnumber == 3 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.calssing") }}
          </div>
        </div>
        <div class="navtitleinner" @click="changegourment((newnumber = 4))">
          <img
            v-if="newnumber == 4"
            src="../assets/img/Engineering/tab_icon04red.png"
            alt=""
          />
          <img v-else src="../assets/img/Engineering/tab_icon04.png" alt="" />

          <div :class="newnumber == 4 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.parkservise") }}
          </div>
        </div>
        <div class="navtitleinner" @click="changeProcess((newnumber = 5))">
          <img
            v-if="newnumber == 5"
            src="../assets/img/alltitle/smartred.png"
            alt=""
          />
          <img v-else src="../assets/img/alltitle/smart.png" alt="" />

          <div :class="newnumber == 5 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.shouafter") }}
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getprojectcaselist, getrecprojectcaselist } from "../request/api";

export default {
  data() {
    return {
      allicon: true,
      Squareicon: false,
      menu: false,
      informaicon: false,
      Process: false,
      newnumber: 1,
      newlist: [],
      textpro: [],
    };
  },
  watch: {
    newnumber: function (newV, oldV) {
      console.log(newV, oldV);
      sessionStorage.setItem("newval", newV);
    },
  },
  mounted() {
    this.newnumber = sessionStorage.getItem("newval");

    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  },
  created() {
    this.$route.meta.title = this.$t("login.querythere10");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outmenu(message, b, d);
      this.outpeo(message, b, d);
      this.$route.meta.title = this.$t("login.querythere10");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  methods: {
    outmenu(a, b, c) {
      getprojectcaselist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.newlist = res.data;
      });
    },
    outpeo(a, b, c) {
      getrecprojectcaselist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.textpro = res.data;
      });
    },
    changetitle() {
      // this.$router.push({ path: "/cooperationall" });
      this.$router.push({
        path: "/cooperationall",
        query: { id: this.textpro },
      });
      this.allicon = true;
      this.Squareicon = false;
      this.menu = false;
      this.Process = false;

      this.informaicon = false;
    },
    changeinformation() {
      this.$router.push({ path: "/Engineering" });
      this.informaicon = true;
      this.allicon = false;
      this.Squareicon = false;
      this.Process = false;

      this.menu = false;
    },
    changetitleportrait() {
      this.menu = false;
      this.informaicon = false;
      this.Process = false;

      this.Squareicon = true;
      this.allicon = false;
      // this.$router.push({ path: "/ClassicCase" });
      this.$router.push({
        path: "/ClassicCase",
        query: { id: this.newlist },
      });
    },
    changegourment() {
      this.menu = true;
      this.informaicon = false;

      this.Squareicon = false;
      this.allicon = false;
      this.Process = false;

      this.$router.push({ path: "/ServiceIdea" });
    },
    changeProcess() {
      this.Process = true;
      this.menu = false;
      this.informaicon = false;

      this.Squareicon = false;
      this.allicon = false;
      this.$router.push({ path: "/Process" });
    },
  },
  destroyed() {
    this.newnumber = 1;
    sessionStorage.setItem("newval", 1);
  },
  beforeDestroy() {
    this.newnumber = 1;
    sessionStorage.setItem("newval", 1);
  },
};
</script>
<style scoped lang='less'>
.alltitle {
  .butflylikeso {
	  background: url(../assets/img/Engineering/beautifulthing.png) no-repeat center top;
	  width: 100%;
	  height: 500px;
    img {
      width: 100%;
      height: 500px;
    }
  }
  .fournavoutside {
    .navtitlehead {
      width: 100%;
      height: 70px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      
box-shadow: 0px 6px 10px 0px rgba(139, 139, 139, 0.1);
      .navtitleinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        display: flex;
        cursor: pointer;
        img {
          width: 16px;
		  position: relative;
		  top:-1px;
          /* height: 20px; */
        }
        .navtext {
          margin-left: 6px;
          font-size: 13px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
        .navtextcolored {
          margin-left: 6px;
          font-size: 13px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #9e2b29;
          line-height: 24px;
        }
      }
    }
  }
  .allcontainer {
    width: 100%;
    background-color: #f2f2f2;
  }

  .centerwidth {
    width: 1200px;
    margin: 0 auto;
  }

  .firstwird {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 104px;
  }

  .firstcontent {
   font-size: 28px;
   /* font-family: Arial Black; */
   font-weight: 900;
   color: #000000;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 24px;
  }

  .context {
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    width: 698px;
  }

  .conarrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .conall {
    vertical-align: middle;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 48px;
  }

  .likearrow {
    margin-left: 11px;
    vertical-align: middle;
    width: 23px;
    height: 14px;
  }

  .theretext {
    display: flex;
    margin-top: 104px;
    justify-content: space-between;
  }

  .newspaper {
    width: 386px;
    height: 300px;
    background: #ffffff;
    border-radius: 7px;
  }

  .newspapertwo {
    width: 386px;
    height: 420px !important;
    background: #ffffff;
    border-radius: 7px;
    /*background-color: red;*/
  }

  .paperinner {
    margin: 30px 38px;
  }

  .paperhead {
    display: flex;
    width: 315px;
    border-bottom: 1px solid #e5e4e4;
    padding-bottom: 23px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    f/* ont-family: Helvetica Neue LT Pro; */
    font-weight: normal;
    color: #474545;
  }

  .fontbig {
    margin-top: 29px;
    /*display: flex;*/
    font-size: 18px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
  }

  .imgandfont {
    display: flex;
    margin-top: 30px;
  }

  .leftimgs {
    width: 82px;
    height: 66px;
  }

  .rightfont {
    width: 212px;
    // height: 60px;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #40403f;
    opacity: 0.75;
    margin-left: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .keoutdoor {
    display: flex;
    width: 315px;
    justify-content: flex-end;
  }

  .learnmore {
    width: 88px;
    margin-top: 25px;
    padding-bottom: 10px;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #666666;
    line-height: 13px;
    border-bottom: 1px solid red;
  }

  .twoimgscopy {
    margin-top: 30px;
    width: 310px;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 18px;
  }

  .twoimgscopyTWO {
    margin-top: 0px;
    width: 310px;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 18px;
  }

  .lastfont {
    width: 307px;
    font-size: 18px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 31px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .lasttext {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 29px;
    width: 301px;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #40403f;
    opacity: 0.75;
  }

  .learnmoretwo {
    margin-top: 35px;
  }

  .firstwirdtwo {
    padding-bottom: 100px;
  }
}
</style>
