<template>
  <div class="tezhegn">
    <div class="peark">{{ $t("login.features") }}</div>
    <iframe
      name="topIframe"
      class="kkk"
      width="100%"
      height="1000px"
      :src="link"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: ["link"],

  data() {
    return {};
  },
  created() {
    // console.log(link);
  },
};
</script>
<style scoped lang='less'>
.tezhegn {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  .peark {
    font-size: 1.125rem;
    font-weight: bold;
    color: #000000;
    margin-top: 2.5rem;
  }
  iframe {
    margin-top: 50px;
    border: none;
  }
}
</style>