<template>
  <div class="consumeres">
    <div class="topimgswork">
      <img src="../assets/img/server/custome.png" alt="" />
    </div>
    <div class="centercontain">
      <img src="../assets/img/server/chenghand.png" alt="" />
      <div class="mangfont">
        <span
          >方太的至诚服务是一套以满足客户需要的全方位服务体系，倡导“及时、专业、用心”，强调一个“诚”字。“诚”，是一种态度，大多数人和方太每一次的接触都留下了好印象。</span
        >
        <div>服务目标：打造无与伦比的顾客体验</div>
        <span
          >顾客满意是企业成长的动力，追求打造无与伦比的顾客体验，是方太服务的终极目标；我们会一直努力，直到达成您的满意。</span
        >
        <div>服务原则：顾客永远是对的；以顾客感动为第一标准</div>
        <span
          >方太始终坚持着“人品、企品、产品，三品合一”
          的核心价值观。以“遵守法纪、弘扬道义、诚信经营、和谐发展”为企业社会责任方针。“顾客永远是对的；以顾客感动为第一标准”是方太亘古不变的服务原则。</span
        >
        <div>服务方针：及时、专业、用心</div>
        <span
          >倡导“及时、专业、用心”，以客户为本。我们的进步源于对于您的需求的不断探索。</span
        >
        <div>
          倡导“及时、专业、用心”，以客户为本。我们的进步源于对于您的需求的不断探索。
        </div>
        <span>免费热线：+86 952315或联系致电所在地方太服务热线。</span>
        <div>服务承诺</div>
        <span>24小时投诉电话、电话咨询服务、终身提供维修服务。</span>
        <span
          >我们会在接到您诉求的第一时间联系到您，确保解决您的后顾之忧。</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>
<style lang="less" scoped>
.consumeres {
  .topimgswork {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .centercontain {
    width: 1200px;
    margin: 0 auto;
    img {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 46px;
    }
    .mangfont {
      display: flex;
      flex-direction: column;
      width: 945px;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 129px;
      div {
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #000000;
        margin-top: 49px;
      }
      span {
        font-size: 15px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        line-height: 30px;
        opacity: 0.74;
        margin-top: 19px;
      }
    }
  }
}
</style>