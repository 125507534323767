<template>
  <div class="pearhome">
    <div class="faqas">
      <div class="faqatop"></div>
      <div class="newlistsomes wow animate fadeInUp delay-900ms">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="一致性 Consistency" name="1">
            <template slot="title">
              <div class="newstitle">
                <div class="leftfont">
                  {{ $t("login.Presales") }}
                </div>
              </div>
              <i v-if="contentcontain[0].checkeds" class="handmactingtwo"></i>
              <i v-else class="handmacting"></i>
            </template>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Presales1") }}</div>
              <div>
                {{ $t("login.Presales11") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Presales2") }}</div>
              <div>
                {{ $t("login.Presales12") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Presales3") }}</div>
              <div>
                {{ $t("login.Presales13") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Presales4") }}</div>
              <div>
                {{ $t("login.Presales14") }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="newlistsomes wow animate fadeInUp delay-900ms">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="一致性 Consistency" name="2">
            <template slot="title">
              <div class="newstitle">
                <div class="leftfont">
                  {{ $t("login.Onsale") }}
                </div>
              </div>
              <i v-if="contentcontain[1].checkeds" class="handmactingtwo"></i>
              <i v-else class="handmacting"></i>
            </template>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Onsale1") }}</div>
              <div>
                {{ $t("login.Onsale11") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Onsale2") }}</div>
              <div>
                {{ $t("login.Onsale12") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Onsale3") }}</div>
              <div>
                {{ $t("login.Onsale13") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Onsale4") }}</div>
              <div>
                {{ $t("login.Onsale14") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Onsale5") }}</div>
              <div>
                {{ $t("login.Onsale15") }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="newlistsomes wow animate fadeInUp delay-900ms">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="一致性 Consistency" name="3">
            <template slot="title">
              <div class="newstitle">
                <div class="leftfont">
                  {{ $t("login.Aftersale") }}
                </div>
              </div>
              <i v-if="contentcontain[2].checkeds" class="handmactingtwo"></i>
              <i v-else class="handmacting"></i>
            </template>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Aftersale1") }}</div>
              <div>
                {{ $t("login.Aftersale11") }}
              </div>
            </div>
            <div class="newscontent">
              <div class="surverying">{{ $t("login.Aftersale2") }}</div>
              <div>
                {{ $t("login.Aftersale12") }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      titlechanges: 1,
      activeNames: ["1", "2"],
      contentcontain: [
        {
          checkeds: true,
          name: "1",
          title:
            "What are the maintenance tips for the range hood on a daily basis?",
          sptingone:
            "1. Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingthere:
            "2. Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "3. Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "2",
          checkeds: true,
          title:
            "Do I need to tear off the film on the range hood's decoration cover?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "3",
          checkeds: false,
          title:
            "What shall be paid attention to for the daily cleaning and maintenance of range hood?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "4",
          checkeds: false,
          title: "What role does the Auto Turbo System play?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "5",
          checkeds: false,
          title:
            "Do I need to tear off the film on the range hood's decoration cover?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "6",
          checkeds: false,
          title: "Can I remove the range hood and clean by myself?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
      ],
    };
  },
  created() {
    this.$route.meta.title = this.$t("login.querythere10");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      this.$route.meta.title = this.$t("login.querythere10");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    imgchange(val) {
      this.titlechanges = val;
    },
    handleChange(val) {
      // console.log(val);
      this,
        this.contentcontain.forEach((el) => {
          if (el.checkeds == true) {
            el.checkeds = false;
          }
        });
      this.contentcontain.forEach((el) => {
        console.log(el);

        val.forEach((ob) => {
          if (ob == el.name) {
            el.checkeds = true;
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pearhome {
  .faqas {
    width: 1200px;
    margin: 0 auto;

    .faqatop {
      margin-top: 83px;
      display: flex;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      .faqselfash {
        font-size: 38px;
        // font-family: Arial;
        font-weight: bold;
        color: #231916;
        line-height: 30px;
      }
      .frtextlis {
        margin-top: 32px;
        text-align: center;
      }
      .frtext {
        font-size: 14px;
        // font-family: Arial;
        font-weight: 400;
        color: #231916;
        line-height: 25px;
      }
      .appliance {
        margin-top: 53px;
        display: flex;
        // border-bottom: 1px solid #f2f2f2;
        padding-bottom: 76px;
        .television {
          width: 197px;
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #f2f2f2;
          img {
            width: 48px;
            // height: 40px;
            cursor: pointer;
          }
          .televtext {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            // font-weight: bold;
            color: #231916;
            line-height: 30px;
            cursor: pointer;
          }
          .televtexttwo {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            // font-weight: bold;
            color: rgb(219, 216, 216);
            line-height: 30px;
            cursor: pointer;
          }
        }
        .television:last-child {
          border-right: none;
        }
      }
    }
    .newlistsomes {
      // border-bottom: 1px solid #f2f2f2;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      .newstitle {
        width: 1200px;
        align-items: center;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftfont {
          cursor: pointer;
          font-size: 18px;
          // font-family: Arial;
          font-weight: bold;
          color: #000000;
        }
        .rightimg {
          cursor: pointer;
          width: 21px;
          height: 21px;
        }
      }
      .newscontent {
        margin-top: 25px;
        margin-left: 0px;
        // margin-bottom: 17px;
        font-size: 14px;
        // font-family: Arial;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        .surverying {
          font-size: 12px;
          font-family: Arial;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
        }
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      /deep/ .el-collapse {
        //       border-bottom:1px solid #e5e5e5;
        // border-top: none;
      }
      /deep/ .el-collapse-item__arrow {
        display: none;
      }
      /deep/.el-collapse-item__header {
        border-bottom: none;
      }
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
      }
      .handmacting {
        display: inline-block;
        background-image: url(../assets/img/alltitle/add.png);
        background-size: 98%;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 70% 100%;
        margin-top: 15px;
      }
      .handmactingtwo {
        display: inline-block;
        background-image: url(../assets/img/alltitle/jian.png);
        background-size: 98%;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 70% 100%;
        margin-top: 15px;
      }
    }
    .newlistsomes:last-child {
      margin-bottom: 100px;
    }
  }
}
</style>