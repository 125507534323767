<template>
  <div class="dangerouswrong">
    <!-- <div>{{ reversedMessage }}</div> -->
    <div class="zhediemodel">
      <el-collapse v-model="activeNames" @change="handleChange">
        <!-- <el-collapse-item title="Installation Diagram" name="1"> -->
        <el-collapse-item
          :title="title1"
          v-for="item in titlim"
          :key="item.id"
          name="1"
        >
          <!-- <img
            class="checkimg"
            src="../assets/img/detailimg/manqiu.png"
            alt=""
          /> -->
          <img class="checkimg" :src="item" alt="" />
        </el-collapse-item>
        <!-- <el-collapse-item title="Kitchen Scene" name="2">
          <img
            class="checkimg"
            src="../assets/img/detailimg/manysix.png"
            alt=""
          />
        </el-collapse-item> -->
      </el-collapse>
      <img class="checkimg" :src="forexample" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["titlim", "forexample"],
  data() {
    return {
      activeNames: ["1"],
    };
  },
  created() {},
  computed: {
    reversedMessage: function () {
      // `this` 指向 vm 实例
      return this.titlim;
    },
    title1() {
      return this.$t("login.installation");
    },
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="less" scoped>
.dangerouswrong {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .zhediemodel {
    // margin-top: 20px;
    /deep/ .el-collapse {
      border-top: none;
    }
    /deep/.el-collapse-item__header {
      border-bottom: none;
      font-size: 18px;
      // font-family: Arial;
      font-weight: bold;
      margin-top: 40px;
      height: 21px;
      line-height: 21px;
      color: #000000;
    }
    /deep/ .el-collapse-item__wrap {
      border-bottom: none;
    }
    .checkimg {
      margin-top: 40px;
      width: 1200px;
    }
  }
}
</style>