<template>
  <div class="alltitle">
    <div class="banner wow animate fadeInUp"></div>
    <!-- <img src="../assets/img/alltitle/news-top.jpg" alt="" /> -->
    <!-- <el-carousel  height="570px">
      <el-carousel-item v-for="(item, index) in list_img" :key="index">
        <img class="headerimg" :src="item.url" alt="" />
       
      </el-carousel-item>
    </el-carousel> -->
    <div class="fournavoutside wow animate fadeInUp delay-200ms">
      <div class="navtitlehead">
        <div class="navtitleinner" @click="changetitle((newnumber = 1))">
          <img
            v-if="newnumber == 1"
            src="../assets/img/alltitle/tab_icon01red.png"
            alt=""
          />
          <img v-else src="../assets/img/alltitle/tab_icon01.png" alt="" />
          <div :class="newnumber == 1 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.home") }}
          </div>
        </div>
        <div class="navtitleinner" @click="changeinformation((newnumber = 2))">
          <img
            v-if="newnumber == 2"
            src="../assets/img/alltitle/tab_icon03red.png"
            alt=""
          />
          <img v-else src="../assets/img/alltitle/tab_icon03.png" alt="" />

          <div :class="newnumber == 2 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.informationing") }}
          </div>
        </div>
        <div
          class="navtitleinner"
          @click="changetitleportrait((newnumber = 3))"
        >
          <img
            v-if="newnumber == 3"
            src="../assets/img/alltitle/tab_icon04red.png"
            alt=""
          />
          <img v-else src="../assets/img/alltitle/tab_icon04.png" alt="" />
          <div :class="newnumber == 3 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.squareimage") }}
          </div>
        </div>
        <div class="navtitleinner" @click="changegourment((newnumber = 4))">
          <img
            v-if="newnumber == 4"
            src="../assets/img/alltitle/tab_icon02red.png"
            alt=""
          />
          <img v-else src="../assets/img/alltitle/tab_icon02.png" alt="" />

          <div :class="newnumber == 4 ? 'navtextcolored' : 'navtext'">
            {{ $t("login.gourmet") }}
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import {
  getnewslist,
  getbooklist,
  getvideolist,
  getrecnewslist,
  getrecvideolist,
  getreccookbooklist,
} from "../request/api";

export default {
  data() {
    return {
      newnumber: 4,
      // allicon: true,
      // Squareicon: false,
      // menu: false,
      // informaicon: false,
      list_img: [
        { url: require("../assets/img/alltitle/news-top.jpg") },
        // { url: require("../assets/img/alltitle/mangfood.png") },
        // { url: require("../assets/img/alltitle/mangfood.png") },
      ],
      newlist: [],
      chuannum: 0,
      menulist: [],
      videolist: [],
      firstlist: [],
      secondlisst: [],
      thirdlist: [],
    };
  },
  watch: {
    newnumber: function (newV, oldV) {
      console.log(newV, oldV);
      sessionStorage.setItem("newval", newV);
    },
  },
  mounted() {
    this.newnumber = sessionStorage.getItem("newval");
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },

  created() {
    console.log(this.$route.query.tnum);
    console.log(this.$route.query.newnumbertype);
    this.newnumber = this.$route.query.newnumbertype;
    console.log(this.newnumber);
    // this.chuannum = this.$route.query.tnum;
    // if (this.chuannum == 100) {
    //   this.newnumber = sessionStorage.getItem("newval");
    // } else if (this.chuannum == "undefined") {
    //   this.newnumber = 1;
    // }

    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outnewes(message, b, d);
      this.outbooklist(message, b, d);
      this.outvideolist(message, b, d);
      this.outrecnewslist(message, b, d);
      this.outrecvideolist(message, b, d);
      this.outreccookbooklist(message, b, d);
    });
  },
  destroyed() {
    this.newnumber = 1;
    sessionStorage.setItem("newval", 1);
  },
  beforeDestroy() {
    this.newnumber = 1;
    sessionStorage.setItem("newval", 1);
  },
  methods: {
    outreccookbooklist(a, b, c) {
      getreccookbooklist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.thirdlist = res.data;
      });
    },
    outrecvideolist(a, b, c) {
      getrecvideolist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.secondlisst = res.data;
      });
    },
    outrecnewslist(a, b, c) {
      getrecnewslist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.firstlist = res.data;
      });
    },
    outvideolist(a, b, c) {
      getvideolist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.videolist = res.data;
      });
    },
    outbooklist(a, b, c) {
      getbooklist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.menulist = res.data;
      });
    },
    outnewes(a, b, c) {
      getnewslist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.newlist = res.data;
      });
    },
    changetitle() {
      // this.$router.push({ path: "/alltitle" });
      this.$router.push({
        path: "/alltitle",
        query: {
          id: this.firstlist,
          sid: this.secondlisst,
          tid: this.thirdlist,
        },
      });
      this.allicon = true;
      this.Squareicon = false;
      this.menu = false;
      this.informaicon = false;
    },
    changeinformation() {
      this.$router.push({
        path: "/information",
        query: { id: this.newlist },
      });
      this.informaicon = true;
      this.allicon = false;
      this.Squareicon = false;
      this.menu = false;
    },
    changetitleportrait() {
      this.menu = false;
      this.informaicon = false;

      this.Squareicon = true;
      this.allicon = false;
      // this.$router.push({ path: "/portrait" });
      this.$router.push({
        path: "/portrait",
        query: { id: this.videolist },
      });
    },
    changegourment() {
      this.menu = true;
      this.informaicon = false;

      this.Squareicon = false;
      this.allicon = false;
      // this.$router.push({ path: "/menu" });
      this.$router.push({
        path: "/menu",
        query: { id: this.menulist },
      });
    },
  },
};
</script>
<style scoped lang='less'>
.alltitle {
  .headerimg {
    width: 100%;
    height: 570px;
    position: relative;
  }
  .happinesses {
    position: absolute;
    top: 346px;
    left: 124px;
    width: 804px;
    // height: 200px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    .startsiin {
      font-size: 36px;
      font-family: Arial Black;
      font-weight: bold;
      color: #b1b4aa;
      line-height: 60px;
    }
    .commitekit {
      font-size: 22px;
      margin-top: 38px;
      /* font-family: Arial; */
      font-weight: 400;
      color: #c7cac1;
      line-height: 30px;
    }
    .proandmore {
      margin-top: 66px;
      display: flex;
      .morebeaytiful {
        width: 156px;
        height: 55px;
        background: #ab1a30;
        opacity: 0.82;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 18px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
      }
      .butflys {
        margin-left: 40px;
        width: 222px;
        height: 55px;
        background: #000000;
        opacity: 0.6;
        border-radius: 9px 9px 9px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .fournavoutside {
    .navtitlehead {
      width: 100%;
      height: 68px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .navtitleinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        display: flex;
        cursor: pointer;
        img {
          width: 20px;
          /* height: 20px; */
        }
        .navtext {
          margin-left: 6px;
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
        .navtextcolored {
          margin-left: 6px;
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #9e2b29;
          line-height: 24px;
        }
      }
    }
  }
  .allcontainer {
    width: 100%;
    background-color: #f2f2f2;
  }

  .centerwidth {
    width: 1200px;
    margin: 0 auto;
  }

  .firstwird {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 104px;
  }

  .firstcontent {
    font-size: 38px;
    /* font-family: Arial Black; */
    font-weight: 400;
    color: #000000;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 24px;
  }

  .context {
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    width: 698px;
  }

  .conarrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .conall {
    vertical-align: middle;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 48px;
  }

  .likearrow {
    margin-left: 11px;
    vertical-align: middle;
    width: 23px;
    height: 14px;
  }

  .theretext {
    display: flex;
    margin-top: 104px;
    justify-content: space-between;
  }

  .newspaper {
    width: 386px;
    height: 300px;
    background: #ffffff;
    border-radius: 7px;
  }

  .newspapertwo {
    width: 386px;
    height: 420px !important;
    background: #ffffff;
    border-radius: 7px;
    /*background-color: red;*/
  }

  .paperinner {
    margin: 30px 38px;
  }

  .paperhead {
    display: flex;
    width: 315px;
    border-bottom: 1px solid #e5e4e4;
    padding-bottom: 23px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .fontbig {
    margin-top: 29px;
    /*display: flex;*/
    font-size: 18px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
  }

  .imgandfont {
    display: flex;
    margin-top: 30px;
  }

  .leftimgs {
    width: 82px;
    height: 66px;
  }

  .rightfont {
    width: 212px;
    // height: 60px;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #40403f;
    opacity: 0.75;
    margin-left: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .keoutdoor {
    display: flex;
    width: 315px;
    justify-content: flex-end;
  }

  .learnmore {
    width: 88px;
    margin-top: 25px;
    padding-bottom: 10px;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #666666;
    line-height: 13px;
    border-bottom: 1px solid red;
  }

  .twoimgscopy {
    margin-top: 30px;
    width: 310px;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 18px;
  }

  .twoimgscopyTWO {
    margin-top: 0px;
    width: 310px;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 18px;
  }

  .lastfont {
    width: 307px;
    font-size: 18px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 31px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .lasttext {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 29px;
    width: 301px;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #40403f;
    opacity: 0.75;
  }

  .learnmoretwo {
    margin-top: 35px;
  }

  .firstwirdtwo {
    padding-bottom: 100px;
  }
}
.banner {
  background: url(../assets/img/alltitle/news-top.jpg) no-repeat center top;
  width: 100%;
  height: 500px;
}
</style>
