<template>
  <div class="cxwproduct">
    <div class="RecommendedProducts" v-show="recommend.length != 0">
      {{ $t("login.tuijianpro") }}
    </div>
    <div class="adjustes">
      <div
        class="thetopfather"
        v-for="item in recommend"
        :key="item.id"
        @click="todetail(item.code)"
      >
        <div class="ztoomast">
          <div class="numberes">{{ item.code }}</div>
          <div class="intereting" v-html="item.intro"></div>
          <img class="NUMten" :src="item.thumb" alt="" />
        </div>
        <div class="moresome">
          <img src="../assets/img/detailimg/fotysix.png" alt="" />
          <span>MORE</span>
        </div>
      </div>
      <div class="thetopfather"></div>
      <div class="thetopfather"></div>

      <!-- <div class="thetopfather">
        <div class="ztoomast">
          <div class="numberes">CXW-202</div>
          <div class="intereting">intelligent self-adjusting</div>
          <img
            class="NUMten"
            src="../assets/img/alltitle/aposticon1.png"
            alt=""
          />
        </div>
        <div class="moresome">
          <img src="../assets/img/detailimg/fotysix.png" alt="" />
          <span>MORE</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["recommend"],

  data() {
    return {};
  },
  methods: {
    todetail(a) {
      console.log(111111);
      this.$router.push({ path: "/productdetail", query: { id: a } });
      this.$bus.$emit("changedetail", a);
      location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.cxwproduct {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 100px;
  .RecommendedProducts {
    font-size: 18px;
    // font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-top: 48px;
  }
  .adjustes {
    margin-top: 46px;
    display: flex;
    position: relative;
    width: 1226px;
    justify-content: space-between;
    margin-bottom: 100px;
    flex-wrap: wrap;
    .thetopfather {
      width: 30%;
      padding: 0 24px 0 0;
      // height: 425px;
      margin-bottom: 20px;

      flex-direction: column;
      transition: transform 0.3s;
      .ztoomast {
        height: 282px;
        align-items: center;
        display: flex;
        background: #fafafa;
        flex-direction: column;

        .numberes {
          font-size: 16px;
          // font-family: Arial;
          font-weight: bold;
          color: #333333;
          margin-top: 38px;
        }
        .intereting {
          font-size: 14px;
          // font-family: Arial;
          font-weight: 400;
          color: #999999;
          margin-top: 8px;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          // height: 38px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2; //显示的行
          width: 80%;
        }
        .NUMten {
          margin-top: 15px;
          margin-bottom: 30px;
          width: 130px;
          // height: 210px;
        }
      }
      .moresome {
        width: 365px;
        margin-top: 15px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 38px;
        display: none;
        img {
          width: 10px;
          height: 8px;
          margin-right: 23px;
          margin-left: 10px;
        }
        span {
          text-align: right;
          font-size: 12px;
          // font-family: Arial;
          font-weight: 400;
          color: #000000;
        }
      }
    }
    .thetopfather .ztoomast:hover {
      cursor: pointer;
      transform: translate(0, -5px);
      box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.05);
    }
  }
}
</style>