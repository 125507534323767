<template>
  <div class="consumeres">
    <div class="topimgswork wow animate fadeInUp">
      <img src="../assets/img/kitchen/fatherandmother.png" alt="" />
    </div>
    <div class="centercontain">
      <span class="yijizhichu wow animate fadeInUp delay-100ms"
        >HAPPINESS STARTS IN YOUR KITCHEN</span
      >
      <div class="panbott wow animate fadeInUp delay-300ms">
        <div class="lovefamily">
          Though no one can provide a single shared definition of happiness,
          most homes do share a similar “feeling of happiness” ——there is
          warmth, familiar tastes and smells, companionship, sharing, the flow
          of emotions. The kitchen, most importantly, is the source of family
          happiness. For 25 years, FOTILE has put the “For the happiness of
          hundreds of millions of families”first and foremost in its efforts.
        </div>
        <div class="lovefamily sheatchan">
          We strive to find a better solution to the problem of cooking grease
          and fumes, thus opening up the kitchen and letting it return from the
          farthest corner of the home to its rightful place at the center of the
          family. Time in the kitchen is no longer a lonely chore. It is now
          filled with the joy of seeing each other, working side-by-side and
          moments of togetherness. Our continuous improvements in technology and
          quality allow more people to experience the joy of cooking and savor
          life’s real beauty. From nutrition to flavor, unfailingly provide the
          meticulous care your family deserves. Fill you children’s memories of
          growing up with familiar flavors, the warmth of companionship and
          peace of mind.
        </div>
        <div class="lovefamily">
          Make the kitchen cleaner, keep your family closer, and protect your
          and your family's health. Enjoy your happy kitchen and leave the rest
          to us.
        </div>
        <div class="lovefamily salbid">
          From 1996 to 2021, from China to the world, in more than 30 countries
          and for more than 17 million families, FOTILE makes "happiness" its
          mission and is determined to creat happiness starting in the kitchen
          for people all over the world.
        </div>
      </div>
      <img
        class="grandfathersmile wow animate fadeInUp"
        src="../assets/img/kitchen/groundfather.png"
        alt=""
      />
      <div class="homewecha">
        <div class="mosehofa wow animate fadeInUp delay-100ms">
          <img src="../assets/img/kitchen/icon.png" alt="" />
          <div class="jusetson">
            <span>HAPPY KITCHEN IS THE CENTER OF HOME</span>
            <div>
              The kitchen should be more than just a corner of labor, but a
              place that connects people's emotions and conveys love and
              affection. It is the center of a home.
            </div>
          </div>
        </div>
        <div class="mosehofa wow animate fadeInUp delay-200ms">
          <img src="../assets/img/kitchen/icon1.png" alt="" />
          <div class="jusetson">
            <span>Happy kitchen should be open</span>
            <div>
              The kitchen blends with various spaces at home and the cooking
              process can facilitate family members to face and interact with
              each other.
            </div>
          </div>
        </div>
        <div class="mosehofa wow animate fadeInUp delay-300ms">
          <img src="../assets/img/kitchen/icon2.png" alt="" />
          <div class="jusetson">
            <span>Happy kitchen should be a public space</span>
            <div>
              The kitchen is a place where the family hangs out, so it should be
              equipped with complete functions, no fumes, and healthy for a long
              time stay of the family.
            </div>
          </div>
        </div>
        <div class="mosehofa wow animate fadeInUp delay-400ms">
          <img src="../assets/img/kitchen/icon3.png" alt="" />
          <div class="jusetson">
            <span>Fotile Happiness Starts In Your Kitchen</span>
            <div>
              FOTILE is committed to turning the kitchen into an open place
              filled with love and happiness and bringing this lifestyle to
              every corner of the world.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.consumeres {
  .topimgswork {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .centercontain {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .yijizhichu {
      margin-top: 40px;
      font-size: 28px;
      // font-family: Arial;
      font-weight: bold;
      color: #151515;
      line-height: 48px;
    }
    .panbott {
      margin-top: 30px;
      .lovefamily {
        font-size: 14px;
        // font-family: Arial;
        // font-weight: 400;
        color: #151515;
        line-height: 24px;
      }
      .sheatchan {
        margin-top: 25px;
      }
      .salbid {
        margin-top: 25px;
      }
    }
    .grandfathersmile {
      margin-top: 44px;
    }
    .homewecha {
      margin-top: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      .mosehofa {
        display: flex;
        width: 530px;
        margin-bottom: 78px;
        img {
          vertical-align: middle;
          width: 48px;
          height: 48px;
          margin-top: 10px;
        }
        .jusetson {
          vertical-align: middle;
          margin-left: 20px;
          span {
            font-size: 18px;
            // font-family: Arial;
            font-weight: bold;
            color: #151515;
            line-height: 36px;
            text-transform: uppercase;
          }
          div {
            width: 500px;
            margin-top: 6px;
            font-size: 14px;
            font-family: Arial;
            font-weight: 400;
            color: #151515;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>