<template>
  <!-- <div class="allcontainer" style="height: 600px">
    <img src="../assets/img/qx.png" style="margin-top: 300px" />
  </div> -->
  <div class="goodid wow animate fadeInUp">
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen wow animate fadeInUp">
        {{ $t("login.gorgeous") }}
      </div>
      <div class="formtoyou wow animate fadeInUp">
        {{ $t("login.authority") }}
      </div>
      <div class="centerbox wow animate fadeInUp">
        <div class="cnererleft wow animate fadeInUp">
          <img src="../assets/img/alltitle/may1.png" alt="" />
          <span>{{ $t("login.lens") }}</span>
        </div>
        <div class="cnererleft wow animate fadeInUp">
          <img src="../assets/img/alltitle/may2.png" alt="" />
          <span class="constant">{{ $t("login.erosion") }}</span>
        </div>
      </div>
    </div>
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen">{{ $t("login.fatal") }}</div>
      <div class="formtoyou">
        {{ $t("login.motive") }}
      </div>
      <div class="centerbox">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may3.png" alt="" />
          <span>{{ $t("login.cicumstance") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may4.png" alt="" />
          <span class="constanttwo">{{ $t("login.embody") }}</span>
        </div>
      </div>
    </div>
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen">{{ $t("login.mutiply") }}</div>
      <div class="formtoyou">
        {{ $t("login.wresetle") }}
      </div>
      <div class="centerbox">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may5.png" alt="" />
          <span>{{ $t("login.pasture") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may6.png" alt="" />
          <span class="constant">{{ $t("login.approval") }}</span>
        </div>
      </div>
    </div>
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen">
        {{ $t("login.handing") }}
      </div>
      <div class="formtoyou">
        {{ $t("login.morning") }}
      </div>
      <div class="centerboxtwo">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may7.png" alt="" />
          <span>{{ $t("login.handsed") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may8.png" alt="" />
          <span class="">{{ $t("login.hairsing") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may9.png" alt="" />
          <span class="">{{ $t("login.ducking") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may10.png" alt="" />
          <span class="">{{ $t("login.bite") }}</span>
        </div>
      </div>
    </div>
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen">{{ $t("login.rabbit") }}</div>
      <div class="formtoyou">
        {{ $t("login.cakeing") }}
      </div>
      <div class="centerbox">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may11.png" alt="" />
          <span>{{ $t("login.badanmad") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may12.png" alt="" />
          <span class="constant">{{ $t("login.smalling") }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="containerover wow animate fadeInUp">
      <div class="ourowen">Fast and efficient on-site feedback mechanism</div>
      <div class="formtoyou">
        Project manager responsibility system, the whole process of project
        management cooperation, Ensure on-site feedback is fast and efficient.
      </div>
      <div class="centerbox">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may11.png" alt="" />
          <span>Project manager system</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may12.png" alt="" />
          <span class="constant">The whole process Full responsibility</span>
        </div>
      </div>
    </div> -->
    <div class="containerover wow animate fadeInUp">
      <div class="ourowen">{{ $t("login.trutle") }}</div>
      <div class="formtoyou">
        {{ $t("login.weeksing") }}
      </div>
      <div class="centerboxthere">
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may13.png" alt="" />
          <span>{{ $t("login.jscket") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may14.png" alt="" />
          <span class="constant">{{ $t("login.shirting") }}</span>
        </div>
        <div class="cnererleft">
          <img src="../assets/img/alltitle/may15.png" alt="" />
          <span class="constant">{{ $t("login.sweater") }}</span>
        </div>
      </div>
    </div>
    <div class="fogroup wow animate fadeInUp">
      <div class="group1 wow animate fadeInUp">{{ $t("login.hurtsing") }}</div>
      <div class="group2 wow animate fadeInUp">
        {{ $t("login.potato") }}
      </div>
      <div class="group2 group3 wow animate fadeInUp">
        {{ $t("login.tomato") }}
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  created() {
    this.$route.meta.title = this.$t("login.querythere10");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });

    this.$bus.$on("add", (message, b, d) => {
      this.$route.meta.title = this.$t("login.querythere10");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.allcontainer {
  width: 100%;
  height: 400px;
  background-color: #f2f2f2;
  text-align: center;
  img {
    margin-top: 100px;
    width: 80px;
  }
  p {
    margin-top: 0px;
    font-size: 14px;
    color: #898989;
  }
}
.goodid {
  padding-bottom: 200px;
  overflow: hidden;
  .containerover {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 120px;
    .ourowen {
      margin-top: 120px;
      font-size: 28px;
      font-family: Arial;
      font-weight: bold;
      color: #000000;
      line-height: 30px;
      text-transform: uppercase;
      text-align: center;
    }
    .formtoyou {
      width: 900px !important;
      text-align: center;
      margin: 0 auto;
      line-height: 20px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
      opacity: 0.85;
      margin-top: 29px;
    }
    .centerbox {
      width: 600px;
      margin: 0 auto;
      // background-color: red;
      margin-top: 51px;
      display: flex;
      justify-content: space-around;
      .cnererleft {
        display: flex;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // margin-right: 20px;
        width: 246px;
        img {
          width: 100px;
          height: 100px;
        }
        span {
          width: 274px;
          font-size: 14px;
          margin-top: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a53b39;
        }
        .constant {
          width: 196px;
        }
        .constanttwo {
          width: 246px;
        }
      }
      .cnererleft:first-child {
        margin-right: 60px;
      }
    }
    .centerboxtwo {
      width: 600px;
      margin: 0 auto;
      // background-color: red;
      margin-top: 51px;
      display: flex;
      justify-content: space-around;
      .cnererleft {
        display: flex;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // margin-right: 20px;
        width: 246px;
        img {
          width: 100px;
          height: 100px;
        }
        span {
          width: 274px;
          font-size: 14px;
          margin-top: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a53b39;
        }
      }
    }
    .centerboxthere {
      width: 600px;
      margin: 0 auto;
      // background-color: red;
      margin-top: 51px;
      display: flex;
      justify-content: space-around;
      .cnererleft {
        display: flex;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // margin-right: 20px;
        width: 246px;
        img {
          width: 100px;
          height: 100px;
        }
        span {
          width: 274px;
          font-size: 18px;
          margin-top: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a53b39;
        }
      }
    }
  }
  .fogroup {
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .group1 {
      font-size: 28px;
      font-family: Arial;
      font-weight: bold;
      color: #a53b39;
      line-height: 30px;
      margin-top: 120px;
    }
    .group2 {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      margin-top: 39px;
    }
    .group3 {
      margin-top: 39px;
    }
  }
}
</style>
