var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"toplunbo wow animate fadeInUp"},[_c('el-carousel',{attrs:{"autoplay":true,"trigger":"click","arrow":"never","height":"512","loop":false}},_vm._l((_vm.banlist),function(item){return _c('el-carousel-item',{key:item.id},[_c('div',{staticClass:"allmosting",style:({ backgroundImage: 'url(' + item.img + ')' }),on:{"click":function($event){return _vm.toirfrom(
              item.link,
              item.link_type,
              item.cate,
              item.type,
              item.name
            )}}})])}),1)],1),_c('div',{staticClass:"filterandreset wow animate fadeInUp delay-300ms"},[_c('div',{staticClass:"rangehodd"},[_vm._v(_vm._s(_vm.tylpclass))]),_c('div',{staticClass:"twosailder"},[_c('div',{staticClass:"sailderleft"},[_c('div',{staticClass:"saillefandtop"}),_c('div',{staticClass:"iwantan"},[_c('el-collapse',{on:{"change":_vm.handleChange},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.lefttype),function(item){return _c('el-collapse-item',{key:item.id,attrs:{"title":item.title,"name":item.index}},[_c('div',{staticClass:"mostchosessche"},_vm._l((item.value),function(val){return _c('el-checkbox',{key:val.id,on:{"change":function($event){return _vm.tochackpost(item.type, val.id, $event)}}},[_vm._v(_vm._s(val.name))])}),1)])}),1)],1)]),_c('el-checkbox-group',{attrs:{"max":3},on:{"change":_vm.mostcheck},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('div',{staticClass:"sailderright"},_vm._l((_vm.manyproductlist),function(item){return _c('div',{key:item.id,staticClass:"sailderrightinner wow animate fadeInUp"},[_c('div',{staticClass:"patenrted"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"needcoulum"},[_c('div',{staticClass:"openwide"},[_c('div',{staticClass:"mowleft"},[_vm._v(_vm._s(item.intro))]),(item.link)?_c('div',{staticClass:"mowright",on:{"click":function($event){return _vm.toother(item.link, item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("login.seriesing"))+" ")]):_vm._e()])]),_c('div',{staticClass:"gmzerofi"},[_vm._l((item.data),function(ele){return _c('div',{key:ele.id,staticClass:"cooking"},[_c('img',{attrs:{"src":ele.thumb,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.addtodetail(
                      ele.code,
                      ele.is_outside,
                      ele.outside_url,
                      item.code
                    )}}}),_c('span',{staticClass:"emggwith",on:{"click":function($event){$event.stopPropagation();return _vm.addtodetail(
                      ele.code,
                      ele.is_outside,
                      ele.outside_url,
                      item.code
                    )}}},[_vm._v(_vm._s(ele.code))]),_c('span',{staticClass:"yoursckk",on:{"click":function($event){$event.stopPropagation();return _vm.addtodetail(
                      ele.code,
                      ele.is_outside,
                      ele.outside_url,
                      item.code
                    )}}},[_vm._v(_vm._s(ele.tags))]),_c('el-checkbox',{attrs:{"label":ele.id,"disabled":ele.disabled},on:{"change":function($event){return _vm.gaibi(ele.id)}},model:{value:(ele.checked),callback:function ($$v) {_vm.$set(ele, "checked", $$v)},expression:"ele.checked"}},[_vm._v(_vm._s(_vm.$t("login.contrast")))])],1)}),_c('div',{staticClass:"havenoname"}),_c('div',{staticClass:"havenoname"}),_c('div',{staticClass:"havenoname"})],2)])}),0)])],1)]),_c('div',{staticClass:"contrastfather",on:{"click":function($event){_vm.drawer = true}}},[_c('img',{attrs:{"src":require("../assets/img/alltitle/bookiconfack.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("login.contrast")))]),(_vm.choosecheck)?_c('div',{staticClass:"zhansize"},[_vm._v(_vm._s(_vm.choosecheck))]):_vm._e()]),_c('el-drawer',{attrs:{"title":"我是标题","visible":_vm.drawer,"with-header":false,"size":"560px"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"suchasting"},[_c('div',{staticClass:"suchasinner"},[_c('img',{attrs:{"src":"","alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("login.protobi")))]),_c('div')])]),_vm._l((_vm.addche),function(item,index){return _c('div',{key:index,staticClass:"airapple"},[_c('div',{staticClass:"airappleinner"},[_c('img',{staticClass:"contiollself",attrs:{"src":item.thumb,"alt":""}}),_c('div',{staticClass:"springthing"},[_c('div',{staticClass:"mgboserive2"},[_vm._v(_vm._s(item.code))]),_c('div',{staticClass:"mgboserive3"},[_vm._v(" "+_vm._s(item.intro)+" ")])]),_c('img',{staticClass:"delesomting",attrs:{"alt":""}})])])}),_c('div',{staticClass:"emptyting",on:{"click":_vm.emptyclear}},[_c('span',[_vm._v(_vm._s(_vm.$t("login.obempty")))])]),_c('div',{staticClass:"begin",on:{"click":function($event){$event.stopPropagation();return _vm.addings('constrast')}}},[_vm._v(" "+_vm._s(_vm.$t("login.osure"))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }