<template>
  <div class="superdetail">
    <div class="ProductDetails">{{ $t("login.prodetail") }}</div>
    <div class="thevolum" v-if="innerdetails.length >= 1">
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[0].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->
          <!-- {{ innerdetails[0].title }} -->
          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[0].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
      <!-- <img src="../assets/img/detailimg/juseven.png" alt="" /> -->
      <img :src="innerdetails[0].img" alt="" />
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 2">
      <!-- <img src="../assets/img/detailimg/fireserener.png" alt="" /> -->
      <img :src="innerdetails[1].img" alt="" />
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[1].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->
          <!-- {{ innerdetails[1].title }} -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[1].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
          <!-- {{ innerdetails[1].intro }} -->
        </div>
      </div>
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 3">
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[2].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->
          <!-- {{ innerdetails[2].title }} -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[2].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
          <!-- {{ innerdetails[2].intro }} -->
        </div>
      </div>
      <!-- <img src="../assets/img/detailimg/looklike.png" alt="" /> -->
      <img :src="innerdetails[2].img" alt="" />
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 4">
      <!-- <img src="../assets/img/detailimg/widown.png" alt="" /> -->
      <img :src="innerdetails[3].img" alt="" />

      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[3].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[3].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 5">
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[4].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[4].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
      <!-- <img src="../assets/img/detailimg/looklike.png" alt="" /> -->
      <img :src="innerdetails[4].img" alt="" />
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 6">
      <!-- <img src="../assets/img/detailimg/widown.png" alt="" /> -->
      <img :src="innerdetails[5].img" alt="" />

      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[5].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[5].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 7">
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[6].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[6].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
      <!-- <img src="../assets/img/detailimg/looklike.png" alt="" /> -->
      <img :src="innerdetails[6].img" alt="" />
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 8">
      <!-- <img src="../assets/img/detailimg/widown.png" alt="" /> -->
      <img :src="innerdetails[7].img" alt="" />

      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[7].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[7].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 9">
      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[8].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[8].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
      <!-- <img src="../assets/img/detailimg/looklike.png" alt="" /> -->
      <img :src="innerdetails[8].img" alt="" />
    </div>
    <div class="thevolum thevolumTWO" v-if="innerdetails.length >= 10">
      <!-- <img src="../assets/img/detailimg/widown.png" alt="" /> -->
      <img :src="innerdetails[9].img" alt="" />

      <div class="firstname">
        <div class="accordinges" v-html="innerdetails[9].title">
          <!-- INTELLIGENT SELF-ADJUSTING ACCORDING TO THE VOLUME OF COOKING FUMES -->

          <div class="bstyyse"></div>
        </div>
        <div class="fullyes" v-html="innerdetails[9].intro">
          <!-- Equipped with Intelligent Self-Adjusting Smart Wing-like Surround
          Suction Plate, which is able to adjust its elevation by 8-12cm
          according to the volume of cooking fumes, closer to the “golden fume
          control zone” where the fumes spread on a large scale, ensuring that
          the butterfly wing can fully intercept the fumes in different speeds. -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["innerdetails"],

  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.superdetail {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  .ProductDetails {
    font-size: 18px;
    // font-family: Arial;
    font-weight: bold;
    color: #000000;
    margin-top: 40px;
  }

  .thevolum {
    display: flex;
    margin-top: 43px;
    .firstname {
      display: flex;
      flex-direction: column;
      width: 50%;
      background-color: #fafafa;
      align-items: center;
      justify-content: center;
      // text-align: left;
      .accordinges {
        // width: 279px;
        width: 379px;
        line-height: 24px;
        font-size: 18px;
        // font-family: Arial;
        font-weight: bold;
        color: #000000;
      }
      .bstyyse {
        width: 21px;
        height: 2px;
        margin-top: 10px;
        background-color: #000000;
        // border-bottom: 1px solid #000000;
      }
      .fullyes {
        margin-top: 33px;
        width: 379px;
        font-size: 14px;
        // font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    img {
      width: 598px;
      height: 532px;
    }
  }
  .thevolumTWO {
    margin-top: 0px;
  }
}
</style>