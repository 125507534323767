import { render, staticRenderFns } from "./ServiceIdea.vue?vue&type=template&id=26a6b64d&scoped=true&"
import script from "./ServiceIdea.vue?vue&type=script&lang=js&"
export * from "./ServiceIdea.vue?vue&type=script&lang=js&"
import style0 from "./ServiceIdea.vue?vue&type=style&index=0&id=26a6b64d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a6b64d",
  null
  
)

export default component.exports