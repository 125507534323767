<template>
  <div class="allword">
    <div class="distribtion">
      <div class="dstrouble wow animate fadeInUp">
        {{ $t("login.allwholeworld") }}
      </div>
      <div class="manytext wow animate fadeInUp delay-100ms">
        {{ $t("login.wholedetail") }}
      </div>
      <div class="mapallwo">
        <div class="loveleft wow animate fadeInUp delay-500ms">
          <div class="queryinner">
            <div class="states">United States</div>
            <div class="store">Experience store:</div>
            <div class="numberone">173,888</div>
            <div class="dioperst">
              <span>Directly operated store:</span>
              <span>88,888</span>
            </div>
            <div class="dioperst dioperstwet">
              <span>Franchise store:</span>
              <span>80,000</span>
            </div>
            <div class="dioperst dioperstwet">
              <span>Experience Hall:</span>
              <span>5,000</span>
            </div>
            <div class="lastenter">ENTER</div>
          </div>
        </div>
        <div class="amearmap wow animate fadeInUp delay-200ms">
          <img
            class="bigbackimg"
            src="../assets/img/alltitle/whoworld.png"
            alt=""
          />
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon1"></el-button>
          </el-popover>
          <!-- <div class="addicon addicon1"></div> -->

          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon2"></el-button>
          </el-popover>

          <div class="addicon addicon3"></div>
          <div class="china">Ghina</div>

          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon4"></el-button>
          </el-popover>

          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon5"></el-button>
          </el-popover>
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon6"></el-button>
          </el-popover>
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon7"></el-button>
          </el-popover>
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon8"></el-button>
          </el-popover>
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon9"></el-button>
          </el-popover>
          <el-popover
            placement="top-start"
            offset="100"
            width="200"
            trigger="hover"
          >
            <div class="popoverImg" style="height: 50px; float: left">
              <img
                style="
                  margin-top: 5px;
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                "
                src="../assets/img/global/US.png"
                alt
              />
            </div>
            <div
              style="width: 130px; height: 50px; margin-left: 15px; float: left"
            >
              <div style="font-size: 14px; color: #898989; margin-top: 5px">
                Experience store
              </div>
              <div style="font-size: 18px; color: #000000; font-weight: bold">
                173,888
              </div>
            </div>
            <el-button slot="reference" class="addicon addicon10"></el-button>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.allword {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  // background-color: red;
  .distribtion {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .dstrouble {
      margin-top: 100px;
      text-align: center;
      font-size: 28px;
      // font-family: Arial;
      font-weight: 900;
      color: #000000;
      line-height: 30px;
    }
    .manytext {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 975px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #3c3c3c;
      line-height: 24px;
      // opacity: 0.75;
      margin-top: 24px;
      text-align: center;
    }
    .mapallwo {
      margin-top: 100px;
      display: flex;
      // align-items: center;
      justify-content: center;
      .loveleft {
        z-index: 9;
        width: 261px;
        height: 360px;
        background: #ffffff;
        box-shadow: 0px 0px 53px 0px rgba(0, 0, 0, 0.05);
        opacity: 1;
        border-radius: 13px;
        margin-top: 50px;
        .queryinner {
          margin: 0px 0px 0px 30px;
          .states {
            font-size: 28px;
            font-family: Arial;
            font-weight: bold;
            color: #000000;
            line-height: 25px;
            margin-top: 34px;
          }
          .store {
            margin-top: 17px;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: #797979;
            line-height: 25px;
          }
          .numberone {
            margin-top: 18px;
            font-size: 35px;
            font-family: Arial;
            font-weight: 400;
            color: #000000;
            line-height: 21px;
          }
          .dioperst {
            display: flex;
            align-items: center;
            margin-top: 26px;
            span:first-child {
              font-size: 12px;
              font-family: Arial;
              font-weight: 400;
              color: #797979;
              line-height: 25px;
            }
            span:last-child {
              margin-left: 8px;
              font-size: 18px;
              font-family: Arial;
              font-weight: 400;
              color: #000000;
            }
          }
          .dioperstwet {
            margin-top: 16px;
          }
          .lastenter {
            margin-top: 25px;
            width: 201px;
            height: 35px;
            background: #000000;
            box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.13);
            border-radius: 4px 4px 4px 4px;
            font-size: 13px;
            font-family: Arial;
            font-weight: 400;
            color: #faf8f8;
            line-height: 21px;
            text-align: center;
            line-height: 35px;
          }
        }
      }
      .amearmap {
        margin-left: -65px;
        position: relative;
        .bigbackimg {
          width: 1012px;
          height: 432px;
        }
        .addicon {
          width: 15px;
          height: 21px;
          position: absolute;
          top: 125px;
          left: 198px;
          background-image: url(../assets/img/alltitle/addressblack.png);
        }

        .addicon:hover {
          background-image: url(../assets/img/alltitle/addressred.png);
          cursor: pointer;
        }
        /deep/ .el-button {
          padding: 0;
          background-color: transparent;
          border-style: none;
        }
        .addicon1 {
          width: 15px;
          height: 21px;
        }

        .addicon2 {
          top: 160px;
          left: 680px;
        }
        .addicon3 {
          top: 144px;
          left: 757px;
          background-image: url(../assets/img/alltitle/addressred.png);
        }
        .china {
          font-size: 12px;
          font-weight: bold;
          position: absolute;
          width: 50px;
          height: 24px;
          border-radius: 99px;
          color: #ffffff;
          background: #9e2b29;
          top: 143px;
          left: 780px;
          text-align: center;
          line-height: 24px;
        }
        .addicon4 {
          top: 207px;
          left: 763px;
        }
        .addicon5 {
          top: 212px;
          left: 813px;
        }
        .addicon6 {
          top: 235px;
          left: 762px;
        }
        .addicon7 {
          top: 234px;
          left: 789px;
        }
        .addicon8 {
          top: 222px;
          left: 754px;
        }
        .addicon9 {
          top: 290px;
          left: 851px;
        }
        .addicon10 {
          top: 323px;
          left: 951px;
        }
      }
    }
  }
}
</style>