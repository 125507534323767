import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { WOW } from "wowjs";

import 'animate.css'
// Vue.prototype.$WOW=WOW


import VueI18n from 'vue-i18n';
Vue.use(VueI18n);


import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

const bus = new Vue()
export default bus
Vue.prototype.$bus=new Vue()



import Print from 'vue-print-nb'

Vue.use(Print)




import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title  */
  if (to.name) {
    document.title = to.name
  }
  next()
})

// 多语言切换
const i18n = new VueI18n({
	locale: 'en', //设置默认语言
	messages:{
		'zh': require('./lang/zh'),
		'en': require('./lang/en'),
		'other': require('./lang/other'),
		'Indonesia': require('./lang/Indonesia'),
		'simples': require('./lang/simples'),
	}
});


new Vue({
  router,
  store,
  i18n,   
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    
    document.title = to.meta.title
  }
  next()
})