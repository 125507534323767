<template>
  <div>
    <button @click="switch_the_language('zh')">中文/English</button>
    <button @click="switch_the_language('en')">英文/English</button>
    <button @click="switch_the_language('other')">其他/English</button>
    <button @click="switch_the_language('Indonesia')">其他/English</button>
    <button @click="switch_the_language('simples')">繁体/English</button>
    <el-button :plain="true" @click="toemail">打开消息提示</el-button>

    <h1>{{ $t("login.title") }}</h1>
    <div class="djjd">
      按时开学大哥快啥额借款人说的GV耳朵根据二看大哥而动房管局科技第八名额的附件血常规VR我快递放假彼尔德发你吧
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: this.$t("language.name"),
    };
  },

  methods: {
    // 注3：增加语言切换函数
    switch_the_language(a) {
      if (a === "zh") {
        this.$i18n.locale = "zh";
      } else if (a === "en") {
        this.$i18n.locale = "en";
      } else if (a === "other") {
        this.$i18n.locale = "other";
      } else if (a === "Indonesia") {
        this.$i18n.locale = "Indonesia";
      } else if (a === "simples") {
        this.$i18n.locale = "simples";
      }
    },
    toemail(e) {
      console.log(111);
      e.preventDefault();
      if (this.$message) {
        console.log("ddddd");
        this.$message.close();
      } else {
        console.log("ttttt");
        this.$message({
          message: "恭喜你，这是一条成功消息",
          type: "success",
        });
      }
    },
  },
};
</script>


<style lang='less' scoped>
.djjd {
  width: 200px;
  // overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
